<script>
export default {
    name: 'ToastHn',
    data(){
        return{
            ps: {
                st:null,
                type:'',
                title:'',
                mask:false,
                duration:0,
                callBack: ()=>{}
            }
        }
    },
    methods: {
        show(obj) {
            let pv = this.ps
            if (pv.st){
                clearTimeout(pv.st)
            }
            pv.callBack=obj.callBack || (() => {}),
            pv.title=obj.title || "",
            pv.mask=obj.mask || false,
            pv.duration=obj.duration || 2500,
            pv.type=obj.type || ""
            let d = pv.duration
            if (d > 0 && pv.type!="load") {
                pv.st = setTimeout(() => {
                    this.hide()
                    pv.callBack && pv.callBack()
                }, d)
            }
            if(pv.type=="load"){
                pv.mask = true
            }
        },
        hide() {
            this.ps.type="",
            this.ps.mask=false
        },
        load(obj){
            obj = obj || {}
            obj.type="load"
            this.show(obj)
        },
        warn(obj){
            obj.type="warn"
            this.show(obj)
        },
        msg(obj){
            obj.type="msg"
            this.show(obj)
        },
        fail(obj){
            obj.type="fail"
            this.show(obj)
        },
        tip(obj){
            obj.type="tip"
            this.show(obj)
        }
    }
}
</script>
<template>
    <div class="toast">
        <div class="toast-mask" :class="{show:ps.mask}"></div>

        <div class="toast-load" :class="{show:ps.type=='load'}">
            <div class="toast-load-icon"><div class="toast-load-circle"></div></div>
            <div class="toast-load-text" v-if="ps.title">{{ps.title}}</div>
        </div>

        <div class="toast-a" :class="{show:ps.type=='warn'}">
            <div class="toast-icon">!</div>
            <div class="toast-text">{{ps.title}}</div>
        </div>

        <div class="toast-a" :class="{show:ps.type=='msg'}">
            <div class="toast-text">{{ps.title}}</div>
        </div>

        <div class="toast-a" :class="{show:ps.type=='fail'}">
            <div class="toast-icon">×</div>
            <div class="toast-text">{{ps.title}}</div>
        </div>

        <div class="toast-a" :class="{show:ps.type=='tip'}">
            <div class="toast-icon">i</div>
            <div class="toast-text">{{ps.title}}</div>
        </div>
    </div>
</template>
<style>
    .toast{font-size: .16rem;}
    .toast-mask {background-color: rgba(255, 255, 255, 0.1);position: fixed;left: 0;top: 0;width: 100%;height: 100%;z-index: 999998;transition: all 0.3s;opacity: 0;visibility: hidden;}
    .toast-mask.show {opacity: 1;visibility: visible;}
    .toast-load {width: 16em;position: fixed;left: 50%;top: 50%;z-index: 999999;transform: translate(-50%, -50%);padding: 1.42em;opacity: 0;visibility: hidden;text-align: center;border-radius: .1rem;box-sizing: border-box;background-color: rgba(0,0,0,0.5);}
    .toast-load.show{opacity: 1;visibility: visible;transition: all .3s;}
    .toast-load-text {display: block;font-size: .18rem;color: #fff;line-height: 1;padding-top: 1em;}
    .toast-load-icon{width: 3em;height: 3em;margin: 0 auto;border: 0.3em solid #fff;border-radius: 50%;position: relative;box-sizing: border-box;}
    .toast-load-circle{width: 3.1em;height: 3.1em;box-sizing: border-box;border: 0.4em solid transparent;border-top-color: #2191d1;position: absolute;left: -0.3em;top: -0.3em;border-radius: 50%;animation: spin 0.8s linear infinite;}
    @keyframes spin {0% {transform: rotate(360deg);}100% {transform: rotate(0deg);}}
    .toast-a {width: 16em;position: fixed;top: 50%;left: 50%;z-index: 999999;transform: translate(-50%, -50%);background-color: rgba(0,0,0,0.8);padding: .7em 1em;text-align: center;opacity: 0;visibility: hidden;border-radius: .1rem;box-sizing: border-box;}
    .toast-a.show{opacity: 1;visibility: visible;transition: all .3s;}
    .toast-text {display: inline;vertical-align: middle;font-size: .18rem;color: #fff;line-height: 1.5;}
    .toast-icon{display: inline-block;vertical-align: middle;width: 1.5em;height: 1.5em;border: 1px solid #fff;border-radius: 50%;margin-right: .13rem;line-height: 1.5;color: #fff;}
    @media(max-width: 1584px){
        .toast{font-size: 13px;}
    }
    @media(max-width: 1472px){
        .toast-load-text,
        .toast-text{font-size: 14px;}
    }
</style>


