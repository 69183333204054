<script>
import ApplyIndex from './components/ApplyIndex.vue'

export default {
    name: 'App',
    components: {
        ApplyIndex
    }
}
</script>

<template>
    <div id="app">
        <ApplyIndex />
    </div>
</template>

<style>
    html{-webkit-font-smoothing:antialiased;height:100%;touch-action:manipulation}
    blockquote,body,code,dd,div,dl,dt,fieldset,form,h1,h2,h3,h4,h5,h6,input,legend,li,ol,p,pre,td,textarea,th,ul{margin:0;padding:0}
    header,hgroup,nav,menu,main,section,article,summary,details,aside,figure,figcaption,footer{display:block;margin:0;padding:0}
    table{border-collapse:collapse;border-spacing:0}
    table,th,td{border:1px solid black}
    fieldset,img{border:0}
    address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:400}
    dl,ol,ul{list-style-type:none}
    h1,h2,h3,h4,h5,h6{font-size:100%}
    a{text-decoration:none;color:inherit}
    a:hover{text-decoration:none;-webkit-tap-highlight-color:rgba(255,0,0,0);outline:0;outline:none;blr:expression(this.onFocus=this.blur())}
    sup{vertical-align:text-top}
    sub{vertical-align:text-bottom}
    textarea{resize:none;outline:none;font-family:Arial,Microsoft Yahei}
    img{vertical-align:middle;max-width:100%;height:auto;image-rendering:-webkit-optimize-contrast;image-rendering:-o-crisp-edges;image-rendering:-moz-crisp-edges;image-rendering:crisp-edges;-ms-interpolation-mode:nearest-neighbor}
    button,input[type=submit],input[type=text],input[type=button]{-webkit-appearance:none;-webkit-tap-highlight-color:rgba(255,0,0,0);outline:0;blr:expression(this.onFocus=this.blur())}
    input{outline:0;border-radius:0}
    input::-webkit-input-safebox-button{display:none}
    body{color:#000;font-family:Arial,Microsoft Yahei}
    header,footer,main,header *,footer *,main *{box-sizing:border-box}
    *{box-sizing:border-box}
    @media (max-width:1200px){
        img{image-rendering:auto}
    }
    html{font-size:calc(100vw / 1920 * 100)}
    /* @media(max-width: 1500px){
        html{font-size: calc(1500px / 1920 * 100);}
    } */
    body{font-size: calc(16 * 1rem / 100);}

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder{color:#999}
    input:-moz-placeholder,
    textarea:-moz-placeholder{color:#999}
    input::-moz-placeholder,
    textarea::-moz-placeholder{color:#999}
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder{color:#999}

    .main{width:100%;min-height:100vh;position:relative;padding-top:2.1rem;padding-bottom:.6rem}
    .bg{width:100%;height:100%;position:absolute;z-index:1;left:0;top:0;background-image: url(/public/images/bg1.jpg);background-size:cover;background-position:center;background-repeat:no-repeat}
    .topm{position:absolute;z-index:2;top:0;width:10.2rem;left:50%;transform:translateX(-50%);max-width:100%}
    .topm .hei{width:100%;padding-top:2.4rem;background-image: url(/public/images/top.png);background-size: cover; background-position: center;background-repeat: no-repeat ;}
    .topm .hit{position:absolute;left:0rem;top:0;width:100%;height:1.5rem;display:flex;flex-wrap:wrap;align-items:center;text-align:center;justify-content:center;color:#fff;font-weight:bold;font-size:.48rem;z-index:3;line-height:1.2}
    .content{width:calc(1800 / 1920 * 100%);display:block;margin:0rem auto 0rem;position:relative;z-index:2;background-color:#fff;border-radius:.1rem;display:flex;overflow:hidden}
    .content .conl{display:inline-block;vertical-align:middle;width:calc(440 / 1800 * 100%);position:relative;background-color:rgba(32,145,209,0.06)}
    .content .conl .lont{width:100%;height:100%;display:block;padding:.9rem .45rem 1.2rem;position:relative;z-index:2}
    .content .conl .lont .lontop{width:100%;display:flex;align-items:center;justify-content:space-between;margin-bottom:.25rem;cursor:pointer}
    .content .conl .lont .lontop .lit{display:inline-block;vertical-align:middle;color:#333;line-height:1.3;font-size:.3rem}
    .content .conl .lont .lontop .rc{display:inline-block;vertical-align:middle;width:.21rem;cursor:pointer;transition:all ease 0.3s}
    .content .conl .lont .lontop .rc img{width:100%;display:block}
    .content .conl .lont .lontop .rc.open{transform:rotate(180deg)}
    .content .conl .lont .sinp{width:100%;color:#333;font-size:.18rem;line-height:2;text-align:justify}
    .content .conl .lont .sinp p{line-height:inherit}
    .content .conl .pg{position:absolute;z-index:1;bottom:.3rem;left:50%;transform:translate(-50%);width:2.7rem}
    .content .conl .pg .em{width:100%;padding-top:.85rem;background-image: url(/public/images/i2.png);background-size: cover; background-position: center;background-repeat: no-repeat ;}
    .content .conr{display:flex;flex-wrap:wrap;width:calc(1360 / 1800 * 100%);text-align:center;align-content:center;justify-content:center;padding:0.7rem 0 0.8rem}
    .content .conr .atom{width:calc(500 / 1400 * 100%);display:inline-block;vertical-align:middle;position:relative;border-radius:.1rem;overflow:hidden;margin:0 calc(45 / 1400 * 100%);transition:all ease 0.3s;cursor: pointer;}
    .content .conr .atom a{width:100%;height:100%;display:block;position:absolute;left:0;top:0;z-index:3}
    .content .conr .atom .gg{width:100%;position:relative;overflow:hidden;border-radius:.1rem}
    .content .conr .atom .gg .gm{width:100%;padding-top:5.8rem;background-size: cover; background-position: center;background-repeat: no-repeat ;}
    .content .conr .atom .loms{position:absolute;left:0;top:0;width:100%;height:100%;z-index:2;display:flex;flex-wrap:wrap;align-content:center}
    .content .conr .atom .loms .icon{display:block;margin:0 auto .8rem;width:1.4rem;height:1.4rem}
    .content .conr .atom .loms .icon img{width:100%;display:block}
    .content .conr .atom .loms .vit{width:100%;text-align:center;color:#fff;font-size:.36rem;font-weight:bold;line-height:1.1}
    .content .conr .atom .loms .sit{width:100%;color:#fff;font-size:.18rem;line-height:1.5;margin-top:.1rem}
    .step{position:fixed;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,0.3);z-index:10;visibility: hidden;opacity: 0;transition: all .3s;}
    .step.show{visibility: visible;opacity: 1;}
    .step .sox{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:10;background-color:#fff;border-radius:.1rem;width:calc(100% - 1.2rem);max-height:100vh;padding:.6rem;overflow:auto}
    .step .sox::-webkit-scrollbar{width:.04rem;height:.04rem;scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .sox::-webkit-scrollbar-thumb{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(33,145,209,0.7);background:rgba(33,145,209,0.7);scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .sox::-webkit-scrollbar-track{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(0,0,0,0.1);background:rgba(0,0,0,0.1)}
    .step .sorm{display:block;margin:0 auto;}
    .step .git{width:100%;color:#333;font-size:.36rem;line-height:1;text-align:center;padding-bottom:0.2rem}
    .step .form{width:100%}
    .step .form.sp{padding-top:.1rem}
    .step .row{width:100%;font-size:0;text-align:center;margin:.3rem auto;padding-bottom:.26rem}
    .step .row .name{display:inline-block;vertical-align:middle;font-size:.24rem;color:#333;margin-right:.2rem}
    .step .row .name span{color:#b72c2c}
    .step .row .inputs{display:inline-block;vertical-align:middle;width:5.6rem;font-size:0;border-radius:.1rem}
    .step .row .inputs .inv{display:inline-block;vertical-align:middle;width:calc(100% - 1.15rem);position:relative}
    .step .row .inputs .inv .int{width:100%;display:block;background-color:#f3f3f3;border:none;padding:0 .2rem;line-height:.6rem;height:.6rem;color:#333;font-size:.18rem;border-radius:.1rem 0 0 .1rem}
    .step .row .inputs .inv .xi{position:absolute;z-index:2;top:calc(0.08rem + 100%);left:0;font-size:.16rem;color:#b72c2c;display: none;}
    .step .row .inputs .inv .xi.show{display: block;}
    .step .row .inputs .stn{display:inline-block;vertical-align:middle;width:1.15rem}
    .step .row .inputs .stn .submit{width:100%;background-color:#2191d1;color:#fff;font-size:.16rem;line-height:.6rem;height:.6rem;border:none;border-radius:0 .1rem .1rem 0;cursor: pointer;}
    .step .row .inputs .stn .submit.gray{background-color: #666;pointer-events: none;cursor: default;}
    .step .xmg{width:100%;max-width:9.6rem;background-color:#002657;padding:0.25rem 0.3rem;margin: 0 auto .5rem}
    .step .xmg .xir{width:100%;text-align:center;color:#fff;font-weight:bold;font-size:.3rem}
    .step .xmg .ids{display:block;margin:0.25rem auto 0.2rem;max-width:3.2rem}
    .step .xmg .ids img{width:100%;display:block}
    .step .xmg .imd{display:block;margin:0.12rem auto;max-width:5rem}
    .step .xmg .imd img{width:100%;display:block}
    .step .close{width:100%;font-size:0;margin-top:.4rem;text-align:center}
    .step .close .vtn{display:inline-block;vertical-align:middle;background-color:#2191d1;color:#fff;line-height:.6rem;width:2.4rem;padding:0 .7rem;cursor:pointer;font-size:.18rem;border-radius:.3rem;margin:0 .1rem}
    .step .close .vtn.gray{background-color:#ccc;}
    .step .close .vtn.disabled{background-color:#666;cursor:no-drop;pointer-events: none;}
    .step .vit{width:100%;text-align:center;color:#333;font-size:.36rem;line-height:1;margin-bottom:.1rem}
    .step .vit .blue{color:#2191d1}
    .step .xip{width:100%;text-align:center;color:#b72c2c;font-size:.16rem}
    .step .visit{width:100%}
    .step .visit .vocom{width:100%;display:flex;margin-top:.5rem;margin-bottom:.5rem}
    .step .visit .vocom .vonl{display:inline-block;vertical-align:middle;background-color:#f2f9fc;width:calc(1070 / 1680 * 100%);padding:.2rem}
    .step .visit .vocom .vonl .volop{width:100%;display:flex;justify-content:space-between;align-items:center;margin-bottom:.2rem}
    .step .visit .vocom .vonl .volop .vm{display:inline-block;vertical-align:middle;color:#333;font-size:.24rem}
    .step .visit .vocom .vonl .volop .add{display:inline-block;vertical-align:middle;width:1.4rem;font-size:.18rem;background-color:#8fc320;line-height:.5rem;border-radius:.25rem;color:#fff;padding:0 .25rem;cursor:pointer;text-align:center}
    .step .visit .vocom .vonl .table{width:100%}
    .step .visit .vocom .vonl .table table{width:100%}
    .step .visit .vocom .vonl .table table,
    .step .visit .vocom .vonl .table tr,
    .step .visit .vocom .vonl .table td{border:none}
    .step .visit .vocom .vonl .table tr{background-color:rgba(255,255,255,0.5)}
    .step .visit .vocom .vonl .table tr:nth-child(2n){background-color:#fff}
    .step .visit .vocom .vonl .table td{color:#333;font-size:.16rem;padding:.14rem;text-align:center}
    .step .visit .vocom .vonl .table tr.head{background-color:#2191d1;text-align:center}
    .step .visit .vocom .vonl .table tr.head td{color:#fff}
    .step .visit .vocom .vonl .table .td1{width:calc(260 / 1050 * 100%);text-align:left}
    .step .visit .vocom .vonl .table .td2{width:calc(160 / 1050 * 100%)}
    .step .visit .vocom .vonl .table .td3{width:calc(190 / 1050 * 100%)}
    .step .visit .vocom .vonl .table .td4{width:calc(240 / 1050 * 100%)}
    .step .visit .vocom .vonl .table .td5{width:calc(200 / 1050 * 100%);padding-left: 0;padding-right: 0;}
    .step .visit .vocom .vonl .table .ten{display:inline-block;vertical-align:middle;cursor:pointer;line-height:.36rem;color:#fff;font-size:.18rem;width:auto;padding:0 .15rem;background-color:#2191d1;margin:0 .03rem .05rem;border-radius:.18rem}
    .step .visit .vocom .vonl .table .ten.del{background-color:#e41010}
    .step .visit .vocom .vonr{display:inline-block;vertical-align:middle;width:calc(610 / 1680 * 100%)}
    .step .visit .vocom .vonr .xmg{width:100%;margin-bottom:0}
    .step .visit .vocom .vonr .xmg .xh{width:100%;padding-top:5rem;background-size: cover;background-position: center;background-repeat: no-repeat;}
    .step .confirm{display:block;margin:0 auto;max-width:11.05rem;width:100%}
    .step .confirm .vit{margin-bottom:.2rem}
    .step .confirm .xip-wrap{width: 100%;min-height: .27rem;margin-bottom:.45rem;margin-top: -0.05rem;}
    .step .confirm .xip{width:100%;text-align:center;font-size:.18rem;color:#b72c2c;line-height:1.5}
    .step .confirm .ximpten{width:100%;display:flex;flex-wrap:wrap;justify-content:space-between}
    .step .confirm .ximpten .xonl{display:inline-block;vertical-align:middle;width:calc(825 / 1105 * 100%)}
    .step .confirm .ximpten .xonl .row{width:100%;font-size:0;text-align:center;margin:0 auto .2rem auto;padding-bottom:0rem}
    .step .confirm .ximpten .xonl .row .name{display:inline-block;vertical-align:middle;font-size:.24rem;color:#333;margin-right:.2rem;width:1.3rem}
    .step .confirm .ximpten .xonl .row .name span{color:#b72c2c}
    .step .confirm .ximpten .xonl .row .inputs{display:inline-block;vertical-align:middle;width:calc(100% - 1.5rem);font-size:0;border-radius:.1rem}
    .step .confirm .ximpten .xonl .row .inputs .int{width:100%;display:block;background-color:#f3f3f3;border:none;padding:0 .2rem;line-height:.6rem;height:.6rem;color:#333;font-size:.18rem;border-radius:.1rem}
    .step .confirm .ximpten .xonr{display:inline-block;vertical-align:middle;width:calc(240 / 1105 * 100%)}
    .step .confirm .ximpten .xonr .xotm{width:100%;background-color:#f2f9fc;border-radius:.06rem;border:dashed 1px #2191d1;position:relative;overflow: hidden;}
    .step .confirm .ximpten .xonr .xotm .hei{width:100%;padding-top:100%}
    .step .confirm .ximpten .xonr .xotm .com{position:absolute;left:0;top:0;width:100%;height:100%;z-index:2;display:flex;align-content:center;justify-content:center;flex-wrap:wrap}
    .step .confirm .ximpten .xonr .xotm .com .ic{width:.9rem;height:.9rem;display:block;margin:0 auto .1rem}
    .step .confirm .ximpten .xonr .xotm .com .ic img{width:100%;display:block}
    .step .confirm .ximpten .xonr .xotm .com .cm{width:100%;color:#333;line-height:1.5;text-align:center;font-size:.18rem}
    .step .confirm .ximpten .xonr .xotm .com .cm span{color:#b72c2c}
    .step .confirm .ximpten .xonr .xotm .heom{position:absolute;left:0;top:0;width:100%;height:100%;z-index:123;background-color: #fff;display: none;}
    .step .confirm .ximpten .xonr .xotm .heom.show{display: block;}
    .step .confirm .ximpten .xonr .xotm .heom img{position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);max-width: 100%;max-height: 100%;}
    .step .confirm .ximpten .xonr .pob{width:100%;font-size:0;text-align:center;margin-top:.2rem}
    .step .confirm .ximpten .xonr .pob .ptn{display:inline-block;vertical-align:middle;color:#fff;border-radius:.2rem;line-height:.4rem;width:auto;padding:0 .5rem;cursor:pointer;font-size:.16rem;background-color:#2191d1}
    .step .confirm .ximpten .xonr .pob .file{position: absolute;left: -200vw;top: 0;}
    .step .ptvm{width:100%;display:block;margin:0 auto;max-width:7rem}
    .step .ptvm .photos{background-color:#fbfdfe;margin-top:0.5rem;margin-bottom:.66rem;position: relative;padding: 0.1rem;}
    .step .ptvm .photos>img{width: 100%;height: 100%;position: absolute;left: 0;top: 0;}
    .step .results{width:100%;background-color:#f2f9fc;padding:.2rem .4rem;overflow:auto}
    .step .results::-webkit-scrollbar{width:.04rem;height:.04rem;scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .results::-webkit-scrollbar-thumb{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(33,145,209,0.7);background:rgba(33,145,209,0.7);scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .results::-webkit-scrollbar-track{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(0,0,0,0.1);background:rgba(0,0,0,0.1)}
    .step .results .table{width:100%}
    .step .results .table table{width:100%}
    .step .results .table table,
    .step .results .table tr,
    .step .results .table td{border:none}
    .step .results .table tr{background-color:rgba(255,255,255,0.5)}
    .step .results .table tr:nth-child(2n){background-color:white}
    .step .results .table td{color:#333;font-size:.16rem;padding:.1rem;text-align:center;width:25%}
    .step .results .table tr.head{background-color:#2191d1;text-align:center}
    .step .results .table tr.head td{color:#fff;font-size:.24rem}
    .step .results .table .chtn{display:inline-block;vertical-align:middle;cursor:pointer;line-height:.36rem;color:#fff;font-size:.18rem;width:auto;padding:0 .35rem;background-color:#2191d1;margin:0 .03rem .05rem;border-radius:.18rem}
    .step .results .table .chtn.del{background-color:#e41010}
    .step .results .table .chtn.disabled{background-color:#ccc;cursor:no-drop;pointer-events: none;}
    .step .megom{width:100%;display:block;margin:0 auto;max-width:8.3rem;padding-top:.4rem}
    .step .megom .row{width:100%;font-size:0;text-align:center;margin:0 auto 0.2rem;padding-bottom:0rem;position:relative}
    .step .megom .row.z99{z-index:99}
    .step .megom .row .name{display:inline-block;vertical-align:top;font-size:.24rem;color:#333;margin-right:.2rem;width:1.3rem;line-height:.6rem;text-align: right;}
    .step .megom .row .name span{color:#b72c2c}
    .step .megom .row .select{font-size:0;display:inline-block;vertical-align:middle;border:none;text-align:left;position:relative;width:calc(100% - 1.5rem);z-index:1}
    .step .megom .row .select .select-con{width:100%;display:inline-block;vertical-align:middle;position:relative;}
    .step .megom .row .select .select-con .select-value{display:inline-block;vertical-align:middle;width:100%;line-height:.6rem;height:.6rem;border-radius:.06rem;color:#222;cursor:pointer;padding:0 .2rem;z-index:3;font-size:.18rem;position:relative;background-color:#f3f3f3;border:none}
    .step .megom .row .select .select-con select{position:absolute;width:100%;height:100%;left:0;top:0;z-index:2;opacity:0}
    .step .megom .row .select .select-con select option{color:#666;font-size:.16rem;line-height:2.5;background:#fff}
    .step .megom .row .select .select-con .seluls{position:absolute;font-size:.16rem;left:0;top:100%;min-width:100%;max-height:2rem;overflow:auto;box-sizing:border-box;text-align:left;background-color:rgba(255,255,255,0.9);z-index:10;border-radius:0 0 .06rem .06rem;box-shadow:0rem 0.07rem 0.59rem 0rem rgba(30,112,38,0.16);overflow:auto;padding:.1rem}
    .step .megom .row .select .select-con .seluls .option{width:100%;color:#222;line-height:1.75;cursor:pointer;padding:.05rem 0rem}
    .step .megom .row .select .select-con .seluls::-webkit-scrollbar{width:.04rem;height:.04rem;scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .megom .row .select .select-con .seluls::-webkit-scrollbar-thumb{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(33,145,209,0.3);background:rgba(33,145,209,0.3);scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .megom .row .select .select-con .seluls::-webkit-scrollbar-track{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(0,0,0,0.1);background:rgba(0,0,0,0.1)}
    .step .megom .row .select .select-con:after{font-size: .16rem;display:inline-block;content:"";position:absolute;right:.2rem;top:50%;transform:translateY(-50%);z-index:3;width:.6875em;height:.4375em;background-image:url(/public/images/arrow-down.png);background-size:100% 100%}
    .step .megom .row .inputs{display:inline-block;vertical-align:middle;width:calc(100% - 1.5rem);font-size:0;border-radius:.1rem;position:relative}
    .step .megom .row .inputs .int{width:100%;display:block;background-color:#f3f3f3;border:none;padding:0 .2rem;line-height:.6rem;height:.6rem;color:#333;font-size:.18rem;border-radius:.1rem}
    .step .megom .row .inputs.date .ic{display:inline-block;vertical-align:middle;width:.24rem;position:absolute;left:.2rem;top:50%;transform:translateY(-50%)}
    .step .megom .row .inputs.date .ic img{width:100%;display:block}
    .step .megom .row .inputs.date .int{padding-left:.55rem}
    .step .megom .row .text{display:inline-block;vertical-align:top;width:calc(100% - 1.5rem);font-size:0;border-radius:.1rem;position:relative}
    .step .megom .row .text .area{width:100%;height:1.2rem;display:block;background-color:#f3f3f3;border:none;padding:.18rem .2rem;line-height:1.5;color:#333;font-size:.18rem;line-height:1.5;border-radius:.1rem}
    .step .xox{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:10;background-color:#fff;border-radius:.1rem;width:6rem;max-height:100vh;padding:.6rem;max-width:90%;display:flex;flex-wrap:wrap;align-content:center;justify-content:center}
    .step .xox .xion{width:.8rem;height:.8rem;display:block;margin:0 auto .2rem}
    .step .xox .xion img{width:100%;display:block}
    .step .xox .kit{width:100%;color:#333;line-height:1.2;text-align:center;font-size:.3rem}
    .step .xox .close{width:100%;font-size:0;margin-top:.5rem;text-align:center}
    .step .xox .close .vtn{display:inline-block;vertical-align:middle;background-color:#2191d1;color:#fff;line-height:.5rem;width:1.6rem;padding:0 .3rem;cursor:pointer;font-size:.18rem;border-radius:.25rem;margin:0 .1rem}
    .step .xox .close .vtn.gray{background-color:#ccc}
    .step .xox .close .vtn.disabled{background-color:#666;cursor:no-drop}
    .step .coxm{position:absolute;left:50%;top:50%;transform:translate(0,-50%);z-index:10;width: calc(100% - 1.2rem);margin-left: calc(-50% + 0.6rem);display:block;position:relative;z-index:2;max-height:100vh;background-color:#fff;border-radius:.1rem;display:flex;overflow:hidden}
    .step .coxm .conl{display:inline-block;vertical-align:middle;width:calc(340 / 1800 * 100%);position:relative;background-color:rgba(32,145,209,0.06);overflow:hidden}
    .step .coxm .conl .lont{width:100%;height:100%;display:block;padding:.9rem .4rem;position:relative;z-index:2}
    .step .coxm .conl .lont .lontop{width:100%;display:flex;align-items:center;justify-content:space-between;margin-bottom:.25rem;cursor:pointer}
    .step .coxm .conl .lont .lontop .lit{display:inline-block;vertical-align:middle;color:#333;line-height:1.3;font-size:.3rem}
    .step .coxm .conl .lont .lontop .rc{display:inline-block;vertical-align:middle;width:.21rem;cursor:pointer;transition:all ease 0.3s}
    .step .coxm .conl .lont .lontop .rc img{width:100%;display:block}
    .step .coxm .conl .lont .lontop .rc.open{transform:rotate(180deg)}
    .step .coxm .conl .lont .sinp{width:100%;color:#333;font-size:.18rem;line-height:2;text-align:justify}
    .step .coxm .conl .lont .sinp p{line-height:inherit}
    .step .coxm .conl .pg{position:absolute;z-index:1;bottom:.3rem;left:50%;transform:translate(-50%);width:2.7rem}
    .step .coxm .conl .pg .em{width:100%;padding-top:.85rem}
    .step .coxm .conr{display: inline-block;vertical-align: top;width:calc(1460 / 1800 * 100%);text-align:center;padding:.5rem .5rem .6rem .5rem}
    .step .coxm .conr .atom{width:calc(500 / 1400 * 100%);display:inline-block;vertical-align:middle;position:relative;border-radius:.1rem;overflow:hidden;margin:0 calc(45 / 1400 * 100%);transition:all ease 0.3s}
    .step .coxm .conr .atom a{width:100%;height:100%;display:block;position:absolute;left:0;top:0;z-index:3}
    .step .coxm .conr .atom .gg{width:100%;position:relative;overflow:hidden;border-radius:.1rem}
    .step .coxm .conr .atom .gg .gm{width:100%;padding-top:5.8rem}
    .step .coxm .conr .atom .loms{position:absolute;left:0;top:0;width:100%;height:100%;z-index:2;display:flex;flex-wrap:wrap;align-content:center}
    .step .coxm .conr .atom .loms .icon{display:block;margin:0 auto .8rem;width:1.4rem;height:1.4rem}
    .step .coxm .conr .atom .loms .icon img{width:100%;display:block}
    .step .coxm .conr .atom .loms .vit{width:100%;text-align:center;color:#fff;font-size:.36rem;font-weight:bold;line-height:1.1}
    .step .coxm .conr .atom .loms .sit{width:100%;color:#fff;font-size:.18rem;line-height:1.5;margin-top:.1rem}
    .step .coxm .fit{width:100%;color:#333;text-align:center;font-size:.36rem;line-height:1;margin-bottom:.5rem}
    .step .coxm .pents{width:calc(100% + 0.5rem);max-height:calc(100vh - 3.06rem);overflow:auto;padding-right:.5rem;margin-bottom:.2rem}
    .step .coxm .pents::-webkit-scrollbar{width:.04rem;height:.04rem;scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .coxm .pents::-webkit-scrollbar-thumb{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(33,145,209,0.7);background:rgba(33,145,209,0.7);scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .coxm .pents::-webkit-scrollbar-track{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(0,0,0,0.1);background:rgba(0,0,0,0.1)}
    .step .coxm .pents .pitem{width:100%;padding-bottom: .3rem;}
    .step .coxm .pents .pitem1{padding-top: 1px;}
    .step .coxm .pents .pitem .pitop{width:100%;display:flex;flex-wrap:wrap;align-items:center;justify-content:space-between;margin-bottom: .30rem;}
    .step .coxm .pents .pitem .pitop .pol{display:inline-block;vertical-align:middle;width:auto;color:#333;font-size:.24rem;line-height: 1;}
    .step .coxm .pents .pitem .pitop .pol span{display:inline-block;vertical-align:middle;width:.24rem;position:relative;top:-0.02rem;margin-right:.06rem}
    .step .coxm .pents .pitem .pitop .pol span img{width:100%;display:block}
    .step .coxm .pents .pitem .pitop .por{display:inline-block;vertical-align:middle;width:auto}
    .step .coxm .pents .pitem .pitop .por .xms{display:inline-block;vertical-align:middle;width:auto;font-size:.16rem;line-height:1.3}
    .step .coxm .pents .pitem .pitop .por .xms span{color:#2191d1}
    .step .coxm .pents .pitem .pitop .por .atn{display:inline-block;vertical-align:middle;background-color:#8fc320;color:#fff;cursor:pointer;padding:0 .2rem;font-size:.16rem;line-height:.5rem;border-radius:.25rem;min-width:1.6rem;text-align:center}
    .step .coxm .pents .pitem .row{width:100%;text-align:left;margin-bottom: .2rem;margin-top: 0;padding-bottom:0;display: flex;justify-content: space-between;}
    .step .coxm .pents .pitem .row .riem{display:inline-block;vertical-align:middle;}
    .step .coxm .pents .pitem .row .riem .select{font-size:0;display:inline-block;vertical-align:middle;border:none;text-align:left;position:relative;width:2.8rem;z-index:1}
    .step .coxm .pents .pitem .row .riem .select .select-con{width:100%;display:inline-block;vertical-align:middle;position:relative;}
    .step .coxm .pents .pitem .row .riem .select .select-con .select-value{display:inline-block;vertical-align:middle;width:100%;line-height:.6rem;height:.6rem;border-radius:.06rem;color:#222;cursor:pointer;padding:0 .2rem;z-index:3;font-size:.16rem;position:relative;background-color:#f3f3f3}
    .step .coxm .pents .pitem .row .riem .select .select-con select{position:absolute;width:100%;height:100%;left:0;top:0;z-index:2;opacity:0}
    .step .coxm .pents .pitem .row .riem .select .select-con select option{color:#666;font-size:.16rem;line-height:2.5;background:#fff}
    .step .coxm .pents .pitem .row .riem .select .select-con .seluls{position:absolute;font-size:.16rem;left:0;top:100%;min-width:100%;max-height:2rem;overflow:auto;box-sizing:border-box;text-align:left;background-color:rgba(255,255,255,0.9);z-index:10;border-radius:0 0 .06rem .06rem;box-shadow:0rem 0.07rem 0.59rem 0rem rgba(30,112,38,0.16);overflow:auto;padding:.1rem}
    .step .coxm .pents .pitem .row .riem .select .select-con .seluls .option{width:100%;color:#222;line-height:1.75;cursor:pointer;padding:.05rem 0rem}
    .step .coxm .pents .pitem .row .riem .select .select-con .seluls::-webkit-scrollbar{width:.04rem;height:.04rem;scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .coxm .pents .pitem .row .riem .select .select-con .seluls::-webkit-scrollbar-thumb{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(33,145,209,0.3);background:rgba(33,145,209,0.3);scrollbar-arrow-color:rgba(0,0,0,0.1)}
    .step .coxm .pents .pitem .row .riem .select .select-con .seluls::-webkit-scrollbar-track{border-radius:.1rem;-webkit-box-shadow:inset 0 0 0.1rem rgba(0,0,0,0.1);background:rgba(0,0,0,0.1)}
    .step .coxm .pents .pitem .row .riem .select .select-con:after{font-size: .16rem;display:inline-block;content:"";position:absolute;right:.2rem;top:55%;transform:translateY(-50%);z-index:3;width:0;height:0;border:solid 0.3125em;border-color:#222 transparent transparent transparent}
    .step .coxm .pents .pitem .row .riem .inputs{display:inline-block;vertical-align:middle;width:3rem;font-size:0;border-radius:.1rem;position:relative}
    .step .coxm .pents .pitem .row .riem .inputs .int{width:100%;display:block;background-color:#f3f3f3;border:none;padding:0 .2rem;line-height:.6rem;height:.6rem;color:#333;font-size:.16rem;border-radius:.1rem}
    .step .coxm .pents .pitem .row .riem:nth-child(3n){margin-right:0}
    .step .coxm .pents .pitem .row .name{display:inline-block;vertical-align:middle;font-size:.18rem;color:#333;line-height: 2;margin-right:.2rem;width:auto}
    .step .coxm .pents .pitem .row .name span{color:#b72c2c}
   
    .step .coxm .pents .pitem .row .text{display:inline-block;vertical-align:top;width:calc(100% - 0.99rem);font-size:0;border-radius:.1rem;position:relative}
    .step .coxm .pents .pitem .row .text .area{width:100%;height: 1.2rem;display:block;background-color:#f3f3f3;border:none;padding:.18rem .2rem;line-height:1.5;color:#333;font-size:.16rem;border-radius:.1rem}
    .step .coxm .pents .pitem .row.txt .name{padding-top:0.16rem;line-height: .27rem;}
    .step .coxm .pents .pitem.pitem2 .row .name{width: 5.4em;margin-right: 0;}
    .step .coxm .pents .pitem.pitem2 .row .text{flex: 1;}
    .step .coxm .pents .pitem+.pitem{padding-top: .5rem;border-top: solid 0.02rem #e5f0f8}
    .step .coxm .information{width:100%}
    .step .coxm .information .intiom{width:100%;padding:.3rem;background-color:#f2f9fc;margin-top:0.1rem}
    .step .coxm .information .intiom+.intiom{margin-top:0.2rem}
    .step .coxm .information .intop{width:100%;display:flex;align-items:center;flex-wrap:wrap;justify-content:space-between;margin-bottom:0.2rem}
    .step .coxm .information .intop .number{width:auto;display:inline-block;vertical-align:middle;color:#333;font-size:.18rem;text-align:left}
    .step .coxm .information .intop .number span{color:#2191d1}
    .step .coxm .information .intop .ric{display:inline-block;vertical-align:middle;width:auto;padding:0 0.25rem;color:#333;font-size:.16rem;border:solid 1px rgba(33,145,209,0.3);line-height:2;border-radius:0.1rem}
    .step .coxm .information .intop .ric span{color:#2191d1}
    .step .coxm .information .inform{width:100%;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}
    .step .coxm .information .inform .row{width:100%;margin:0;padding-bottom:0}
    .step .coxm .information .inform .row .riem{display:inline-block;vertical-align:middle;width:48%;margin-right:4%;white-space:nowrap;margin-bottom:.2rem}
    .step .coxm .information .inform .row .riem .name{display:inline-block;vertical-align:middle;font-size:.18rem;color:#333;width:5.55em;margin-right: 0;}
    .step .coxm .information .inform .row .riem .name span{color:#b72c2c}
    .step .coxm .information .inform .row .riem .inputs{display:inline-block;vertical-align:middle;width:2.8rem;font-size:.18rem;width:calc(100% - 5.55em);border-radius:.1rem;position:relative}
    .step .coxm .information .inform .row .riem .inputs .int{width:100%;display:block;background-color:#fff;border:none;padding:0 .2rem;line-height:.6rem;height:.6rem;color:#333;font-size:.16rem;border-radius:.1rem}
    .step .coxm .information .inform .row .riem:nth-child(2n){margin-right:0;width: 46%;}
    .step .coxm .information .inform .innl{width:calc(100% - 2.8rem - 0.4rem);display:inline-block;vertical-align:middle}
    .step .coxm .information .inform .innr{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;width:2.8rem;padding-bottom:0.2rem}
    .step .coxm .information .inform .innr .xotm{width:1.4rem;background-color:#f2f9fc;border-radius:.06rem;border:dashed 1px #2191d1;position:relative;display:inline-block;vertical-align:middle;overflow: hidden;}
    .step .coxm .information .inform .innr .xotm .hei{width:100%;padding-top:100%}
    .step .coxm .information .inform .innr .xotm .com{position:absolute;left:0;top:0;width:100%;height:100%;z-index:2;display:flex;align-content:center;justify-content:center;flex-wrap:wrap}
    .step .coxm .information .inform .innr .xotm .com .ic{width:.4rem;height:.4rem;display:block;margin:0 auto .1rem}
    .step .coxm .information .inform .innr .xotm .com .ic img{width:100%;display:block}
    .step .coxm .information .inform .innr .xotm .com .cm{width:100%;color:#333;line-height:1.5;text-align:center;font-size:.18rem}
    .step .coxm .information .inform .innr .xotm .com .cm span{color:#b72c2c}
    .step .coxm .information .inform .innr .xotm .heom{position:absolute;left:0;top:0;width:100%;height:100%;z-index:4;opacity:0;background-color: #fff;}
    .step .coxm .information .inform .innr .xotm .heom.show{opacity: 1;}
    .step .coxm .information .inform .innr .xotm .heom img{position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);max-width: 100%;max-height: 100%;}
    .step .coxm .information .inform .innr .pob{width:1.2rem;font-size:0;text-align:center;margin-top:.2rem;display:inline-block;vertical-align:middle}
    .step .coxm .information .inform .innr .pob .ptn{display:inline-block;vertical-align:middle;color:#fff;border-radius:.2rem;line-height:.4rem;width:100%;text-align:center;cursor:pointer;font-size:.16rem;background-color:#2191d1}
    .step .coxm .information .inform .innr .pob .ptn span{display:inline-block;vertical-align:middle;width:1em;position:relative;top:-0.02rem;margin-right:0.1rem}
    .step .coxm .information .inform .innr .pob .ptn span img{width:100%;display:block}
    .step .coxm .close{margin-top: 0;padding-top: 0.3rem;}
    .step .succ{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:10;background-color:#fff;border-radius:.1rem;width:12rem;max-height: 100vh;overflow: auto;padding:.6rem;max-width:90%;display:flex;flex-wrap:wrap;align-content:center;justify-content:center;}
    .step .succ .ic{width:0.62rem;height:0.62rem;display:block;margin:0 auto 0.14rem}
    .step .succ .cls{width:100%;color:#333;line-height:1.5;text-align:center;font-size:.36rem}
    .step .succ .jd{width:100%;background-color:#f2f9fc;padding:0.3rem;max-width:6rem;margin:0.4rem auto;display:block;text-align:center;color:#2191d1;font-size:.18rem}
    .step .succ .xm{width:100%;color:#2191d1;font-weight:bold;text-align:center;font-size:.36rem}
    .step .svoc{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:10;background-color:#fff;border-radius:.1rem;width: 6rem;max-height:100vh;padding:0.68rem 0.3rem 0.65rem;}
    .step .svoc .ic{width:0.8rem;height:0.8rem;display:block;margin:0 auto 0.14rem}
    .step .svoc .cls{width:100%;color:#333;line-height:1.5;text-align:center;font-size:.36rem}
    .step .svoc .jd{width:100%;margin:0.28rem auto 0.33rem;display:block;text-align:center;color:#333;font-size:.18rem;line-height: 1.5;}
    .step .svoc .jd span{color:#2191d1}
    .step .svoc .xm{width:100%;color:#2191d1;font-weight:bold;text-align:center;font-size:.36rem;margin-top:0.3rem;line-height: 1;}

    .add-step{z-index: 99;}
    .camera-step{z-index: 150;}
    .visitor-info-step{z-index: 110;}
    .delete-tip{z-index: 150;}
    .the-video{width: 100%;height: 5.1rem;vertical-align: top;position: absolute;left: -200vw;top: 0;object-fit: cover;z-index: 12;}
    .the-video.show{left: 0;position: relative;}
    .the-canvas{width: 100%;height: 5.1rem;vertical-align: top;position: absolute;left: -200vw;top: 0;z-index: 99;}
    .the-canvas.show{left: 0;position: relative;}
    @media(max-width: 1300px){
        .step .coxm .pents .pitem .row .riem .inputs{width: 2.7rem;}
        .step .coxm .information .inform .row .riem .inputs{width: calc(100% - 5.5em);}
    }

    @media(max-width: 1584px){
        .step .row .inputs .inv .xi,
        .step .row .inputs .stn .submit,
        .step .xip,
        .step .visit .vocom .vonl .table td,
        .step .results .table td,
        .step .confirm .ximpten .xonr .pob .ptn,
        .step .megom .row .select .select-con select option,
        .step .megom .row .select .select-con .seluls,
        .step .coxm .pents .pitem .pitop .por .xms,
        .step .coxm .pents .pitem .pitop .por .atn,
        .step .coxm .pents .pitem .row .riem .select .select-con .select-value,
        .step .coxm .pents .pitem .row .riem .select .select-con select option,
        .step .coxm .pents .pitem .row .riem .select .select-con .seluls,
        .step .megom .row .select .select-con:after,
        .step .coxm .pents .pitem .row .riem .select .select-con:after,
        .step .coxm .pents .pitem .row .riem .inputs .int,
        .step .coxm .pents .pitem .row .text .area,
        .step .coxm .information .intop .ric,
        .step .coxm .information .inform .row .riem .inputs .int,
        .step .coxm .information .inform .innr .pob .ptn{font-size: 13px;}
    }
    @media(max-width: 1472px){
        .content .conl .lont .sinp,
        .content .conr .atom .loms .sit,
        .step .row .inputs .inv .int,
        .step .close .vtn,
        .step .visit .vocom .vonl .volop .add,
        .step .visit .vocom .vonl .table .ten,
        .step .confirm .xip,
        .step .confirm .ximpten .xonl .row .inputs .int,
        .step .confirm .ximpten .xonr .xotm .com .cm,
        .step .results .table .chtn,
        .step .megom .row .select .select-con .select-value,
        .step .megom .row .inputs .int,
        .step .megom .row .text .area,
        .step .xox .close .vtn,
        .step .coxm .conl .lont .sinp,
        .step .coxm .conr .atom .loms .sit,
        .step .coxm .pents .pitem .row .name,
        .step .coxm .information .intop .number,
        .step .coxm .information .inform .row .riem .name,
        .step .coxm .information .inform .row .riem .inputs,
        .step .coxm .information .inform .innr .xotm .com .cm,
        .step .succ .jd,
        .step .svoc .jd{font-size: 14px;}
    }
    @media(max-width: 1248px){
        .step .row .name,
        .step .visit .vocom .vonl .volop .vm,
        .step .confirm .ximpten .xonl .row .name,
        .step .results .table tr.head td,
        .step .megom .row .name,
        .step .coxm .pents .pitem .pitop .pol{font-size: 16px;}
    }
    @media(max-width: 1113px){
        .content .conl .lont .lontop .lit,
        .step .xmg .xir,
        .step .xox .kit,
        .step .coxm .conl .lont .lontop .lit{font-size: 18px;}
    }
    @media(max-width: 1024px){
        .content .conr .atom .loms .vit,
        .step .git,
        .step .vit,
        .step .coxm .conr .atom .loms .vit,
        .step .coxm .fit,
        .step .succ .cls,
        .step .succ .xm,
        .step .svoc .cls,
        .step .svoc .xm{font-size: 19px;}
    }
</style>
